import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@mui/material';

export default function ScrollToTopButton() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <IconButton
      onClick={scrollToTop}
      sx={{
        position: 'fixed',
        right: '20px',
        bottom: '20px',
        border: '2px solid rgb(40, 44, 52,0.3)',
        borderRadius: '50%',
      }}>
      <ArrowUpwardIcon />
    </IconButton>
  );
}
