import { Button, Grid } from '@mui/material';
import { TitleLabel } from '../../../components/Label';
import TextInput from '../../../components/TextInput';
import { useContext, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import { customerAPI } from '../../../apis/customerAPI';
import AlertMessage from '../../../components/AlertMessage';

export default function NewCharacter({ charData, setCharData }) {
  const { setLoading } = useContext(UserContext);
  const [originalChar, setOriginalChar] = useState('');
  const [replaceChar, setReplaceChar] = useState('');
  const [alert, setAlert] = useState('');

  async function handleNewCharacter() {
    setLoading(true);
    if (charData.some((item) => item.original_char === originalChar.trim())) {
      setAlert('已存在相同的字');
      setLoading(false);
      return;
    }
    const response = await customerAPI.updateChineseDifficultChar({
      original_char: originalChar.trim(),
      replace_char: replaceChar.trim(),
    });
    setLoading(false);
    if (response?.error) {
      setAlert(response.error.message);
    } else {
      setCharData((prevData) => [
        ...prevData,
        {
          original_char: originalChar.trim(),
          replace_char: replaceChar.trim(),
        },
      ]);
      setOriginalChar('');
      setReplaceChar('');
    }

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  }

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginY: '20px',
        }}>
        <Grid item xs={1}>
          <TitleLabel fontSize="15px">新增字</TitleLabel>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            placeholder=""
            size="small"
            value={originalChar}
            setValue={setOriginalChar}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <TitleLabel fontSize="15px">拆字</TitleLabel>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            placeholder=""
            size="small"
            value={replaceChar}
            setValue={setReplaceChar}
          />
        </Grid>
        <Grid item xs={1}>
          <Button onClick={handleNewCharacter} variant="contained">
            送出
          </Button>
        </Grid>
      </Grid>
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </>
  );
}
