import { api } from './configs/axiosConfigs';

export const adminsAPI = {
  signin: async function (payload) {
    try {
      const response = await api.request({
        url: '/admins/signin',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getOTPCode: async function (codeType) {
    try {
      const response = await api.request({
        url: '/admins/email/verify-code',
        method: 'GET',
        params: { codeType },
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  verifyOTPCode: async function (codeType, payload) {
    try {
      const response = await api.request({
        url: '/admins/email/verify-code',
        method: 'POST',
        params: { codeType },
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  register: async function (payload) {
    try {
      const response = await api.request({
        url: '/admins/register',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  forgotPassword: async function (payload) {
    try {
      const response = await api.request({
        url: '/admins/forgot-password',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  resetPassword: async function (payload) {
    try {
      const response = await api.request({
        url: '/admins/reset-password',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  getAdmins: async function () {
    try {
      const response = await api.request({
        url: '/admins/',
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  createAdmin: async function (payload) {
    try {
      const response = await api.request({
        url: '/admins/',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  updateAdmin: async function (id, payload) {
    try {
      const response = await api.request({
        url: `/admins/${id}`,
        method: 'PUT',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  getRegisterLink: async function (id) {
    try {
      const response = await api.request({
        url: `/admins/${id}/register-link`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getResetPasswordLink: async function (id) {
    try {
      const response = await api.request({
        url: `/admins/${id}/reset-password-link`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getSelfPolicies: async function () {
    try {
      const response = await api.request({
        url: '/admins/me/policies?auto=false',
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
};
