import { useContext, useState } from 'react';
import StyledDataGrid from '../../../components/StyledDataGrid';
import CustomerDialog from './CustomerDialog';
import { Box, CircularProgress } from '@mui/material';
import { customerAPI } from '../../../apis/customerAPI';
import { UserContext } from '../../../context/UserContext';
import moment from 'moment/moment';
import { useEffectOnce } from '../../../utils/UseEffectOnce';

const columns = [
  {
    field: 'tradeTime',
    headerName: '交易時間',
    width: 170,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'orderNumber',
    headerName: '訂單號碼',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'TxType',
    headerName: '交易類別',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'twdTxAmt',
    headerName: '台幣交易金額',
    type: 'number',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'cryptoType',
    headerName: '虛幣交易幣種',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'cryptoAmt',
    headerName: '虛幣交易數量',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'fee',
    headerName: `手續費\n(台幣,發票含稅)`,
    width: 185,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'twdAccount',
    headerName: '台幣帳戶變動',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'TxStatus',
    headerName: '交易狀態',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
];
let tradeInfo = [];

const doneReason = {
  succeed: '成功',
  rejected: '交易失敗',
  cancel: '用戶自行取消',
};

const rejectReason = {
  unpaid: '未付款',
  coinbase: 'rejected by coinbase',
  fluctuation: '超過價格區間',
};

const status = {
  pending: '處理中',
};

const payment = {
  balance: '餘額',
  bank: '轉帳',
  store: '門市付款',
  dcb: '大哥付',
};

const carrierType = {
  A: '會員載具',
  B: '手機條碼',
  C: '自然人憑證',
  P: '紙本',
};

const inVoiceMonth = {
  '01': '01-02月',
  '02': '01-02月',
  '03': '03-04月',
  '04': '03-04月',
  '05': '05-06月',
  '06': '05-06月',
  '07': '07-08月',
  '08': '07-08月',
  '09': '09-10月',
  10: '09-10月',
  11: '11-12月',
  12: '11-12月',
};

export default function CustomerTrade({ id }) {
  const [tradeRecord, setTradeRecord] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const rows = tradeRecord.map((order, index) => {
    const invoicePeriod =
      order.invoiceInfo?.createdTime &&
      order.invoiceInfo?.createdTime.slice(0, 4) +
        ' ' +
        inVoiceMonth[order.invoiceInfo?.createdTime.slice(5, 7)];

    return {
      id: index + 1,
      tradeTime: moment.unix(order.createdTime).format('MM/DD/YYYY HH:mm'),
      orderNumber: order.id,
      TxType: order.side === 'buy' ? '購買' : '賣出',
      twdTxAmt: order.fund,
      cryptoType: order.baseCurrencyId,
      cryptoAmt: order.size,
      fee: order.invoiceInfo?.price,
      twdAccount: order.twdAccountChange,
      TxStatus: doneReason[order.doneReason] || status[order.status],
      rejectReason: rejectReason[order.rejectReason] || '',
      tradeType: payment[order.payment] || '',
      tradeInfo: '*******12345',
      invoiceType: carrierType[order.invoiceInfo?.carrierType] || '',
      carriedId: order.carriedId,
      doneeCode: order.doneeCode,
      npoName: order.npoName,
      invoicePeriod: invoicePeriod,
      invoiceNo: order.invoiceInfo?.invoiceNo,
      invoicePaperAddr: order.invoiceInfo?.address,
      invoiceWonAddr: order.invoiceInfo?.address,
      invoiceInfo: null,
      img: null,
    };
  });
  const handleEvent = (params) => {
    setOpenDialog(true);
    tradeInfo = [
      { title: '訂單號碼', value: params.row.orderNumber },
      { title: '交易時間', value: params.row.tradeTime },
      { title: '交易類別', value: params.row.TxType },
      { title: '台幣交易金額', value: params.row.twdTxAmt },
      { title: '虛幣交易幣種', value: params.row.cryptoType },
      { title: '虛幣交易數量', value: params.row.cryptoAmt },
      { title: '手續費（台幣,發票含稅)', value: params.row.fee },
      { title: '台幣帳戶變動', value: params.row.twdAccount },
      { title: '交易狀態', value: params.row.TxStatus },
      { title: '交易方式', value: params.row.tradeType },
      { title: '交易資訊', value: params.row.tradeInfo },
      { title: '發票期別', value: params.row.invoicePeriod },
      { title: '發票號碼', value: params.row.invoiceNo },
      { title: '發票儲存方式', value: params.row.invoiceType },
      (params.row.invoiceType === '手機條碼' ||
        params.row.invoiceType === '自然人憑證') && {
        title: '載具編號',
        value: params.row.carriedId,
      },
      params.row.doneeCode && {
        title: '捐贈單位',
        value: params.row.npoName,
      },
      { title: '紙本發票地址', value: params.row.invoicePaperAddr },
      { title: '中獎發票地址', value: params.row.invoiceWonAddr },
      // { title: "發票資訊", value: params.row.invoiceInfo },
      // { title: "img", value: params.row.img },
    ].filter(Boolean);

    if (params.row.rejectReason !== '') {
      tradeInfo.splice(9, 0, {
        title: '交易失敗原因',
        value: params.row.rejectReason,
      });
    }
  };

  useEffectOnce(() => {
    const fetchOrder = async () => {
      setIsFetching(true);
      const response = await customerAPI.getOrder(id);
      if (response !== null) setTradeRecord(response);
      setIsFetching(false);
    };

    fetchOrder();
  }, []);

  return (
    <>
      <Box sx={{ height: 'calc(100vh - 450px)', width: '100%' }}>
        {isFetching && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isFetching && (
          <StyledDataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            onRowClick={handleEvent}
          />
        )}
      </Box>
      <CustomerDialog
        tradeInfo={tradeInfo}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
}
