import { Button, Grid } from '@mui/material';
import { SubTitleLabel, TitleLabel } from '../../../components/Label';
import Divider from '@mui/material/Divider';
import { useContext, useState } from 'react';
import TextInput from '../../../components/TextInput';
import { UserContext } from '../../../context/UserContext';
import AlertMessage from '../../../components/AlertMessage';
import { customerAPI } from '../../../apis/customerAPI';

export default function CharacterComponents({ item, index }) {
  const [alert, setAlert] = useState('');
  const { setLoading } = useContext(UserContext);
  const [replaceChar, setReplaceChar] = useState('');
  const [showReplaceChar, setShowReplaceChar] = useState(item.replace_char);

  async function handleReviseCharacter() {
    if (replaceChar.trim().length < 1) {
      setAlert('請輸入文字');
      return;
    }
    setLoading(true);
    const response = await customerAPI.updateChineseDifficultChar({
      original_char: item.original_char,
      replace_char: replaceChar.trim(),
    });
    setLoading(false);
    if (response?.error) {
      setAlert(response.error.message);
    } else {
      setShowReplaceChar(replaceChar.trim());
      setReplaceChar('');
    }
  }

  return (
    <>
      <Grid item xs={12}>
        {index ? <Divider sx={{ marginY: '20px' }} /> : <></>}
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Grid item xs={2}>
            <TitleLabel fontSize="15px">{item.original_char}</TitleLabel>
          </Grid>
          <Grid item xs={4}>
            <SubTitleLabel>{showReplaceChar}</SubTitleLabel>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              placeholder="請輸入新拆字"
              size="small"
              value={replaceChar}
              setValue={setReplaceChar}
            />
          </Grid>
          <Grid item xs={1}>
            <Button onClick={handleReviseCharacter} variant="contained">
              修改
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </>
  );
}
