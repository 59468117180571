import { memo, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  CircularProgress,
} from '@mui/material';
import Panel from '../../components/Panel';
import CharacterComponents from './components/CharacterComponents';
import { SubTitleLabel } from '../../components/Label';
import NewCharacter from './components/NewCharacter';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import { customerAPI } from '../../apis/customerAPI';
import AlertMessage from '../../components/AlertMessage';
import ScrollToTopButton from '../../components/ScrollToTopButton';

export default function ComplexCharacterReview() {
  const [isFetching, setIsFetching] = useState(false);
  const [charData, setCharData] = useState([]);
  const [alert, setAlert] = useState('');
  const [isComplexCharBoxOpen, setIsComplexCharBoxOpen] = useState(false);

  useEffectOnce(() => {
    const fetchUserEddData = async () => {
      setIsFetching(true);
      const response = await customerAPI.getChineseDifficultChar();

      if (response?.error) {
        setAlert(response.error.message);
      } else {
        setCharData(response);
      }

      setIsFetching(false);
    };
    fetchUserEddData();
  }, []);

  function addNewChar() {
    setIsComplexCharBoxOpen((prev) => !prev);
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: '40px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '30px',
        }}>
        <SubTitleLabel>可使用 Ctrl + F 進行查詢</SubTitleLabel>
        <Button onClick={addNewChar} variant="contained">
          新增難字
        </Button>
      </Box>
      <Collapse in={isComplexCharBoxOpen}>
        <Panel gutterBottom>
          <NewCharacter charData={charData} setCharData={setCharData} />
        </Panel>
      </Collapse>
      <Panel gutterBottom>
        <Grid container>
          {isFetching ? (
            <Grid
              item
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CircularProgress />
            </Grid>
          ) : (
            <CharacterList charData={charData} />
          )}
        </Grid>
      </Panel>
      <ScrollToTopButton />
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </Container>
  );
}

const CharacterList = memo(({ charData }) => {
  return charData.map((item, index) => {
    return (
      <CharacterComponents item={item} index={index} key={item.original_char} />
    );
  });
});
